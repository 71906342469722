import { gql } from "@apollo/client";
import { Intent, PopoverInteractionKind } from "@blueprintjs/core";
import {
  AppMetadataField,
  CREATE_HEX_CATEGORY_LINK,
  DELETE_HEX_CATEGORY_LINK,
  DOCS_LINKS,
  HexType,
  SpecialVersionType,
  UPDATE_HEX,
  UPDATE_HEX_VERSION,
} from "@hex/common";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { shallowEqual } from "react-redux";
import styled, { css } from "styled-components";

import { HexButton, HexSpinner, HexTooltip } from "../../hex-components";
import { useHexAOContext } from "../../hex-multiplayer/hexAOContext.js";
import { useHexSelector } from "../../hex-version-multiplayer/state-hooks/hexStateHooks";
import { useHexVersionSelector } from "../../hex-version-multiplayer/state-hooks/hexVersionStateHooks";
import { useUserForMagic } from "../../hooks/magicHooks";
import { useCurrentUser } from "../../hooks/me/useCurrentUser";
import { useProjectVersionEditable } from "../../hooks/sessionOrProjectEditableHooks.js";
import { useOpenSidebar } from "../../hooks/useOpenSidebar";
import {
  MinimalCategory,
  MinimalStatus,
  useProjectLabelsForHex,
} from "../../hooks/useProjectLabelsForHex";
import { MagicOnboardingPopover } from "../../magic/MagicOnboardingPopover";
import { HexVersionMP } from "../../redux/slices/hexVersionMPSlice";
import { buildDocumentTitle } from "../../route/user/project/constants.js";
import { CyData } from "../../util/cypress.js";
import { useHexVersionAOContext } from "../../util/hexVersionAOContext";
import { useProjectContext } from "../../util/projectContext";
import { BorderStyles, ProjectDescription } from "../app/ProjectDescription";
import { ProjectLabels } from "../app/ProjectLabels";
import { CharacterCountIndicator } from "../common/CharacterCountIndicator";
import { ControlledContentEditable } from "../common/ControlledContentEditable";
import { DocsLink } from "../common/DocsLink.js";
import { HumanDate } from "../common/HumanDate";
import { CategoryLabelNavButton } from "../common/labels/CategoryLabelNavButton";
import { StatusLabelNavButton } from "../common/labels/StatusLabelNavButton";
import { useToaster } from "../common/Toasts";
import { ImportReferenceIcon, MagicIcon } from "../icons/CustomIcons";
import { SidebarTab } from "../sidebar/SidebarOptions";

import { EditableNameWrapper } from "./EditableNameWrapper";
import {
  useHexForProjectMetadataQuery,
  useMagicNameProjectMutation,
} from "./ProjectMetadata.generated";

export const DEFAULT_PROJECT_TITLE = "Untitled project";
export const DEFAULT_COMPONENT_TITLE = "Untitled component";
export const TITLE_MAX_LENGTH = 500;

gql`
  mutation MagicNameProject(
    $hexVersionId: HexVersionId!
    $autoTriggered: Boolean
  ) {
    magicNameProject(hexVersionId: $hexVersionId, autoTriggered: $autoTriggered)
  }

  query HexForProjectMetadata($hexId: HexId!) {
    hexById(hexId: $hexId) {
      id
      canEditMetadata
    }
  }
`;

export const ProjectMetadataWrapper = styled.div<{
  $maxWidth?: number;
  $noPadding: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin: 0 auto;
  ${({ $maxWidth }) => $maxWidth != null && `max-width: ${$maxWidth}px;`}
  padding: 20px calc(${({ theme }) => theme.cellMargin}px - 3px) 5px;

  ${({ $noPadding }) =>
    $noPadding &&
    css`
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
    `}
`;

const TopMetadataInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Labels = styled.div`
  display: flex;
  min-height: 22px;
  margin-left: -6px;
`;

const CategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const TitleLengthLimit = styled(CharacterCountIndicator)`
  position: absolute;
  right: 3px;
  bottom: -25px;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  letter-spacing: normal;
`;

export const TitleContentEditable = styled(ControlledContentEditable)<{
  $editable: boolean;
  $placeholder?: boolean;
  $placeholderText?: string;
}>`
  display: inline-block;
  width: 100%;
  max-width: 1130px;

  line-height: inherit;
  white-space: normal;
  overflow-wrap: break-word;

  outline: none;

  &:empty {
    &:after {
      color: ${({ theme }) => theme.fontColor.PLACEHOLDER};

      content: ${({ $placeholderText }) =>
        $placeholderText != null ? `"${$placeholderText}"` : ""};
    }
  }

  ${({ $editable }) => ($editable ? BorderStyles : `padding: 3px;`)}

  ${({ $placeholder }) =>
    $placeholder
      ? css`
          color: ${({ theme }) => theme.fontColor.PLACEHOLDER};
        `
      : css`
          color: ${({ theme }) => theme.fontColor.DEFAULT};
        `}
`;

const MinimalActivityInfo = styled.div`
  display: flex;
  gap: 4px;

  align-items: center;
  min-height: 24px;
  padding-left: 0px;

  color: ${({ theme }) => theme.fontColor.MUTED};

  font-size: ${({ theme }) => theme.fontSize.SMALL};

  line-height: 15px;
`;

const Activity = styled.div`
  display: flex;
  gap: 5px;

  align-items: center;

  cursor: default;
`;

const ActivityPlaceholder = styled.div`
  opacity: 0.6;
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
`;

const MagicNameButton = styled(HexButton)`
  margin-left: -20px;
`;

const MagicNameLoadingSpinner = styled(HexSpinner)`
  margin-left: -20px;
  flex: unset;
`;

export const PROJECT_TITLE_TAG = "project-metadata-title";

interface ProjectMetadataProps {
  className?: string;
  maxWidth?: number;
  noPadding?: boolean;
  view: "logic" | "app";
}

export const ProjectMetadata: React.ComponentType<ProjectMetadataProps> =
  React.memo(function ProjectMetadata({
    className,
    maxWidth,
    noPadding = false,
    view,
  }) {
    const { dispatchAO } = useHexVersionAOContext();
    const { dispatchAO: dispatchHexAO } = useHexAOContext();
    const { hexId, hexType, hexVersionId, inDialog, version } =
      useProjectContext();
    const projectVersionEditable = useProjectVersionEditable();
    const isComponent = hexType === HexType.COMPONENT;
    const currentUser = useCurrentUser();
    const { magicEnabled, onboardedToMagic } = useUserForMagic();
    const toaster = useToaster();

    const canEditMetadata =
      useHexForProjectMetadataQuery({
        variables: { hexId },
      }).data?.hexById?.canEditMetadata ?? false;
    const canEditWithMagic = projectVersionEditable && magicEnabled;

    const metadataEditable =
      canEditMetadata && version === SpecialVersionType.DRAFT;

    const [magicNameProject, { loading: magicNameLoading }] =
      useMagicNameProjectMutation({
        variables: { hexVersionId, autoTriggered: false },
      });

    const magicName = useCallback(async () => {
      try {
        await magicNameProject();
      } catch (e) {
        console.error(e);
        toaster.show({
          message:
            "We encountered an error generating your project title. Please try again later.",
          intent: Intent.DANGER,
        });
      }
    }, [magicNameProject, toaster]);

    const openImportReferences = useOpenSidebar(SidebarTab.IMPORT_REFERENCES);

    const { lastPublisher, projectTitle } = useHexSelector({
      selector: (hex) => ({
        lastPublisher: hex.lastPublisher,
        projectTitle: hex.title,
      }),
      equalityFn: shallowEqual,
    });

    const {
      description,
      preview,
      published,
      publishedDate,
      visibleMetadataFields,
    } = useHexVersionSelector({
      selector: (hexVersion: HexVersionMP) => ({
        published: hexVersion.published,
        description: hexVersion.description,
        preview: hexVersion.preview,
        visibleMetadataFields: hexVersion.visibleMetadataFields,
        // hexVersion.date is only updated when a version is published
        publishedDate: hexVersion.date,
      }),
      equalityFn: shallowEqual,
    });

    let publisherToDisplay = lastPublisher?.name || lastPublisher?.email;
    if (preview || !published) {
      publisherToDisplay = currentUser?.name || currentUser?.email;
    }
    const renderAsDraft = !preview && !published;

    const { appliedCategories, appliedStatus } = useProjectLabelsForHex();

    const [currentTitle, setCurrentTitle] = useState<string>("");
    const [editingTitle, setIsEditingTitle] = useState<boolean>(false);

    const descriptionRef = useRef<HTMLTextAreaElement>(null);

    const visibleMetadataFieldsSet = useMemo(
      () => new Set(visibleMetadataFields),
      [visibleMetadataFields],
    );

    const showName =
      view === "logic" || visibleMetadataFieldsSet.has(AppMetadataField.NAME);

    const showActivityInfo =
      view !== "logic" &&
      ((visibleMetadataFieldsSet.has(AppMetadataField.AUTHOR) &&
        publisherToDisplay) ||
        visibleMetadataFieldsSet.has(AppMetadataField.LAST_EDITED) ||
        visibleMetadataFieldsSet.has(AppMetadataField.LAST_RUN));

    useEffect(() => {
      setCurrentTitle(projectTitle);
    }, [projectTitle]);

    const maybeFocusName = useCallback(() => {
      if (metadataEditable && !editingTitle) {
        setIsEditingTitle(true);
        if (
          projectTitle === DEFAULT_PROJECT_TITLE ||
          projectTitle === DEFAULT_COMPONENT_TITLE
        ) {
          setCurrentTitle("");
        }
      }
    }, [metadataEditable, projectTitle, editingTitle]);

    const saveName = useCallback((): void => {
      const newTitle = currentTitle
        ? currentTitle
        : isComponent
          ? DEFAULT_COMPONENT_TITLE
          : DEFAULT_PROJECT_TITLE;

      // only update the current title and send the AO event if it is under the max charactr count.
      if (newTitle.length <= TITLE_MAX_LENGTH) {
        dispatchHexAO(UPDATE_HEX.create("title", newTitle));
        setCurrentTitle(newTitle);
      }

      setIsEditingTitle(false);
    }, [currentTitle, isComponent, dispatchHexAO]);

    const cancelName = useCallback((): void => {
      const title =
        projectTitle === ""
          ? isComponent
            ? DEFAULT_COMPONENT_TITLE
            : DEFAULT_PROJECT_TITLE
          : projectTitle;
      setCurrentTitle(title);
      descriptionRef.current?.blur();
      setIsEditingTitle(false);
    }, [projectTitle, isComponent]);

    const selectStatus = useCallback(
      (newStatus: MinimalStatus | null): void => {
        dispatchHexAO(UPDATE_HEX.create("statusId", newStatus?.id ?? null));
      },
      [dispatchHexAO],
    );

    const selectCategory = useCallback(
      (category: MinimalCategory, applied: boolean): void => {
        dispatchHexAO(
          applied
            ? DELETE_HEX_CATEGORY_LINK.create({ categoryId: category.id })
            : CREATE_HEX_CATEGORY_LINK.create({ categoryId: category.id }),
        );
      },
      [dispatchHexAO],
    );

    const saveDescriptionCallback = useCallback(
      (newDescription: string | null) => {
        dispatchAO(
          UPDATE_HEX_VERSION.create(
            hexVersionId,
            "description",
            newDescription,
          ),
        );
      },
      [dispatchAO, hexVersionId],
    );

    const statusAdded =
      visibleMetadataFieldsSet.has(AppMetadataField.STATUS) && appliedStatus;

    const categoriesAdded =
      visibleMetadataFieldsSet.has(AppMetadataField.CATEGORIES) &&
      appliedCategories.length > 0;

    const descriptionAdded =
      visibleMetadataFieldsSet.has(AppMetadataField.DESCRIPTION) &&
      description != null;

    const labelsToDisplay = useMemo(() => {
      if (view === "logic") {
        return "all";
      } else {
        if (statusAdded && categoriesAdded) {
          return "all";
        }
        if (statusAdded) {
          return "status";
        }
        if (categoriesAdded) {
          return "categories";
        }
      }
    }, [categoriesAdded, statusAdded, view]);

    const showProjectLabels = useMemo(
      () => view === "logic" || statusAdded || categoriesAdded,
      [categoriesAdded, statusAdded, view],
    );

    const minimalMetadata = (
      <MinimalActivityInfo>
        {(visibleMetadataFieldsSet.has(AppMetadataField.LAST_EDITED) ||
          visibleMetadataFieldsSet.has(AppMetadataField.AUTHOR)) && (
          <span>Published</span>
        )}
        {visibleMetadataFieldsSet.has(AppMetadataField.LAST_EDITED) && (
          <Activity>
            <strong>
              {renderAsDraft ? (
                <HexTooltip
                  content={
                    <>
                      Date is displayed in the published version of your
                      project.{" "}
                      <DocsLink to={DOCS_LINKS.Publishing}>Learn more</DocsLink>
                      .
                    </>
                  }
                  interactionKind={PopoverInteractionKind.HOVER}
                  position="bottom"
                >
                  <ActivityPlaceholder>{"{Date}"}</ActivityPlaceholder>
                </HexTooltip>
              ) : (
                <HumanDate date={publishedDate} />
              )}
            </strong>
          </Activity>
        )}
        {visibleMetadataFieldsSet.has(AppMetadataField.AUTHOR) &&
          publisherToDisplay && (
            <>
              <span>by</span>
              <Activity>
                <strong>
                  {renderAsDraft ? (
                    <HexTooltip
                      content={
                        <>
                          Publisher is displayed in the published version of
                          your project.{" "}
                          <DocsLink to={DOCS_LINKS.Publishing}>
                            Learn more
                          </DocsLink>
                          .
                        </>
                      }
                      interactionKind={PopoverInteractionKind.HOVER}
                      position="bottom"
                    >
                      <ActivityPlaceholder>{"{Publisher}"}</ActivityPlaceholder>
                    </HexTooltip>
                  ) : (
                    publisherToDisplay
                  )}
                </strong>
              </Activity>
            </>
          )}
      </MinimalActivityInfo>
    );

    return (
      <ProjectMetadataWrapper
        $maxWidth={maxWidth}
        $noPadding={noPadding}
        className={className}
      >
        {/* This will override the title provided in the various `*Routes` files, which is useful if the title has just been edited and thus us up-to-date in redux but not in the apollo cache */}
        <Helmet>
          <title>{buildDocumentTitle(currentTitle, metadataEditable)}</title>
        </Helmet>
        <TopMetadataInfo>
          {showProjectLabels && (
            <>
              {metadataEditable ? (
                <ProjectLabels
                  appliedCategories={appliedCategories}
                  appliedStatus={appliedStatus}
                  css="margin-left: -6px;"
                  disabled={!metadataEditable}
                  display={labelsToDisplay}
                  resourceType="hex"
                  onSelectCategory={selectCategory}
                  onSelectStatus={selectStatus}
                />
              ) : (
                <Labels>
                  {statusAdded && (
                    <StatusLabelNavButton
                      homeSubView="all"
                      status={appliedStatus}
                    />
                  )}
                  {categoriesAdded && (
                    <CategoriesWrapper>
                      {appliedCategories.map((category) => (
                        <CategoryLabelNavButton
                          key={category.id}
                          category={category}
                          homeSubView="all"
                        />
                      ))}
                    </CategoriesWrapper>
                  )}
                </Labels>
              )}
            </>
          )}
          {showName && (
            <EditableNameWrapper
              cyData={CyData.HEX_LOGIC_TITLE}
              size="project_title"
            >
              {canEditWithMagic &&
                (magicNameLoading ? (
                  <MagicNameLoadingSpinner
                    data-cy={CyData.MAGIC_LOADING_SPINNER}
                  />
                ) : (
                  <HexTooltip
                    content="Generate title with Magic"
                    position="top"
                  >
                    <MagicOnboardingPopover>
                      <MagicNameButton
                        data-cy={CyData.MAGIC_NAME_PROJECT}
                        disabled={false}
                        extraSmall={true}
                        icon={<MagicIcon />}
                        minimal={true}
                        onClick={onboardedToMagic ? magicName : undefined}
                      />
                    </MagicOnboardingPopover>
                  </HexTooltip>
                ))}
              <TitleContentEditable
                $editable={metadataEditable && !magicNameLoading}
                $placeholder={
                  magicNameLoading ||
                  (!editingTitle &&
                    (currentTitle === DEFAULT_PROJECT_TITLE ||
                      currentTitle === DEFAULT_COMPONENT_TITLE))
                }
                content={
                  magicNameLoading
                    ? "Generating title with Magic..."
                    : currentTitle
                }
                id={PROJECT_TITLE_TAG}
                isEditing={editingTitle}
                maxLength={TITLE_MAX_LENGTH}
                onBlur={saveName}
                onCancel={cancelName}
                onChange={setCurrentTitle}
                onClick={maybeFocusName}
                onSave={saveName}
              />
              {editingTitle && (
                <TitleLengthLimit
                  count={currentTitle.length}
                  max={TITLE_MAX_LENGTH}
                />
              )}
            </EditableNameWrapper>
          )}
          {showActivityInfo && minimalMetadata}
        </TopMetadataInfo>
        {(view === "logic" || descriptionAdded) && (
          <ProjectDescription
            canEdit={metadataEditable}
            onSaveDescription={saveDescriptionCallback}
          />
        )}
        {isComponent && !inDialog && (
          <div>
            <HexButton
              icon={<ImportReferenceIcon />}
              minimal={true}
              small={true}
              onClick={openImportReferences}
            >
              View import references
            </HexButton>
          </div>
        )}
      </ProjectMetadataWrapper>
    );
  });
